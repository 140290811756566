<template>
    <div class="view pa24">
        <div class="flex-a-b-c">
            <div>
                <el-input class="w200 mb10 mr10" placeholder="请输入关键字" v-model="searchData.name" />
                <el-button type="primary" @click="searchFun">搜索</el-button>
            </div>
            <div class="operation-right">
                <el-button class="mb10" type="primary" @click="showConfigTableModel">权益配置</el-button>
            </div>
        </div>
        <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" show-overflow-tooltip />
                <el-table-column prop="nickeName" align="center" label="用户名" show-overflow-tooltip />
                <el-table-column prop="phone" align="center" label="联系电话" show-overflow-tooltip />
                <el-table-column prop="fromLevelName" align="center" label="用户等级" show-overflow-tooltip />
                <el-table-column prop="toLevelName" align="center" label="赠送等级" show-overflow-tooltip />
                <el-table-column prop="num" align="center" label="剩余数量" show-overflow-tooltip />
                <el-table-column align="center" label="操作" width="160">
                    <template slot-scope="scope">
                        <el-button type="text" @click="showUpdateModel(scope.row)">赠送数量</el-button>
                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="编辑数量" :visible.sync="showModel"
            :destroy-on-close="true" width="350px" center>
            <el-form :model="formData" ref="formData" label-width="78px">
                <el-form-item label="赠送等级" prop="toLevelName">
                    {{ formData.toLevelName }}
                </el-form-item>
                <el-form-item label="剩余数量" prop="num">
                    <el-input-number v-model="formData.num"></el-input-number>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" @click="submitForm" v-loading="btnLoading">提 交</el-button>
            </span>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="会员权益" :visible.sync="showConfigTable"
            :destroy-on-close="true" width="80%" center>
            <el-button type="primary" @click="addConfigFun">新增权益</el-button>
            <commonTable :tableData="configData" :loading="configLoading" @handleSizeChange="configHandleSizeChange"
                @handleCurrentChange="configHandleCurrentChange" :currentPage="configCurrentPage" :total="configTotal">
                <template v-slot:table>
                    <el-table-column type="index" align="center" label="序号" width="60" show-overflow-tooltip />
                    <el-table-column prop="fromLevelName" align="center" label="购买等级" show-overflow-tooltip />
                    <el-table-column prop="toLevelName" align="center" label="赠送等级" show-overflow-tooltip />
                    <el-table-column prop="num" align="center" label="赠送/扣除数量" show-overflow-tooltip />
                    <el-table-column align="center" label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button type="text" @click="updateConfigFun(scope.row)">编辑</el-button>
                        </template>
                    </el-table-column>
                </template>
            </commonTable>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="configTitle" :visible.sync="showUpdateConfig"
            :destroy-on-close="true" width="350px" center>
            <el-form :model="configFormData" ref="configFormData" label-width="78px">
                <el-form-item label="购买等级" prop="memberLevel">
                    <el-select v-model="configFormData.fromLevelId" :disabled="configFormType==='update'" placeholder="请选择等级">
                        <el-option v-for="(name, id) in memberLevel" :key="id" :label="name" :value="String(id)">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="赠送等级" prop="memberLevel">
                    <el-select v-model="configFormData.toLevelId" placeholder="请选择等级">
                        <el-option v-for="(name, id) in memberLevel" :key="id" :label="name" :value="String(id)">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="赠送数量" prop="num">
                    <el-input-number v-model="configFormData.num"></el-input-number>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showUpdateConfig = false">取 消</el-button>
                <el-button type="primary" @click="submitConfigData" v-loading="configBtnLoading">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {
    selectAllMemberLevel,
    setMemberLevelGive,
    getMemberLevelGiveList,

    setMemberLevelHave,
    getMemberLevelHaveList,
} from '@/api/member'
// import customPopconfirm from "@/components/common/customPopconfirm";
export default {
    name: "groupingTable",
    components: {
        commonTable,
        // customPopconfirm
    },
    data() {
        return {
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [],
            searchData: { name: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
            //配置列表
            configCurrentPage: 1, //当前页
            configPageSize: 10, //显示条数
            configLoading: false, //表格加载
            configTotal: 0, //总条数
            configData: [],
            showConfigTable: false,
            memberId: 0,
            //模态框配置
            formData: {
                haveId: 0,
                num: 0
            },
            maxNum: 0,
            memberLevel: {},
            btnLoading: false,
            //权益修改配置
            showUpdateConfig: false,
            configFormData: {
                fromLevelId: "",
                toLevelId: "",
                num: 0
            },
            updateGiveId:0,
            configFormType: 'add',
            configBtnLoading: false,
            configTitle:"新增权益",
        };
    },
    watch: {
        watchAssociation(row) {
            this.currentPage = 1
            this.getList();
        }
    },
    computed: {
        watchAssociation() {
            return this.$store.state.checkedAssociation
        }
    },
    created() {
        this.getInitData();
        this.getList();
    },
    methods: {
        /*权益配置 */
        addConfigFun() {
            this.configTitle="新增权益"
            this.showUpdateConfig = true;
            this.configFormData = {
                fromLevelId: "",
                toLevelId: "",
                num: 0
            };
            this.configFormType = 'add';
            this.updateGiveId=0;
        },
        /**@method 编辑权益 */
        updateConfigFun(data) {
            this.configTitle="编辑权益"
            this.showUpdateConfig = true;
            this.configFormData = {
                fromLevelId: String(data.fromLevelId),
                toLevelId: String(data.toLevelId),
                num: data.num,
            };
            this.updateGiveId=data.giveId;
            this.configFormType = 'update';
        },
        /**@method 编辑权益配置 */
        submitConfigData() {
            this.configBtnLoading = true;
            let params = {
                ...this.configFormData
            };
            let message="添加成功";
            if(this.configFormType==='update'){
                params.giveId=this.updateGiveId;
                message="修改成功";
            }
            setMemberLevelGive(params).then(res => {
                this.configBtnLoading = false;
                if (res.code === 200) {
                    this.$message({
                        type: "success",
                        message: message
                    })
                    this.showUpdateConfig = false;
                    this.getConfigList();
                } else {
                    this.$message.error(res.message)
                }
            }).catch(err => {
                this.configBtnLoading = false;
                if (err.code === 201) {
                    this.$message.error(err.message);
                }
            })
        },
        /**@method 显示权益配置列表 */
        showConfigTableModel() {
            this.showConfigTable = true;
            this.getConfigList();
        },
        /**@method 获取权益配置数据 */
        async getConfigList() {
            let params = {
                pageSize: this.configPageSize,
                pageNum: this.configCurrentPage
            };
            try {
                this.configLoading = true;
                let res = await getMemberLevelGiveList(params, 9)
                this.configLoading = false;

                const { data } = res;
                let configData = [];
                for (let row of data.pageInfo.list) {
                    configData.push(row);
                }
                this.configData = configData;
                this.configTotal = data.pageInfo.total;
            } catch (error) {
                this.configLoading = false;
                this.configData = [];
            }
        },
        /**@method 切换行 */
        configHandleSizeChange(val) {
            this.pageSize = val;
            this.getConfigList();
        },
        /**@method 切换下一页 */
        configHandleCurrentChange(val) {
            this.currentPage = val;
            this.getConfigList();
        },
        /*权益配置 */
        /**@method 初始化选项数据 */
        getInitData() {
            selectAllMemberLevel({ companyId: this.userInfo.companyId, isPage: 0 }, 9).then(res => {
                if (res.code === 200) {
                    let memberLevel = {};
                    for (let row of res.data) {
                        memberLevel[row.levelId] = row.levelName;
                    }
                    this.memberLevel = memberLevel;
                }
            })
        },
        /**@method 搜索 */
        searchFun() {
            this.currentPage=1;
            this.getList();
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage
            };
            try {
                this.loading = true;
                let res = await getMemberLevelHaveList(params, 9)
                this.loading = false;

                const { data } = res;
                let tableData = [];
                for (let row of data.pageInfo.list) {
                    tableData.push(row);
                }
                this.tableData = data.pageInfo.list;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        /**@method 编辑数量
         * @param {Object} val - 当前点击行的值
         */
        showUpdateModel(row) {
            this.showModel = true;
            this.formData = {
                haveId: row.haveId,
                num: row.num,
                toLevelName: row.toLevelName
            };
            this.maxNum = row.num;
        },
        /**@method 提交编辑数量 */
        submitForm() {
            this.btnLoading = true;
            let data = {
                haveId: this.formData.haveId,
                num: this.formData.num,
            };
            setMemberLevelHave(data).then(res => {
                this.btnLoading = false;
                if (res.code === 200) {
                    this.$message({
                        message: "编辑成功",
                        type: "success"
                    })
                    this.getList();
                    this.showModel = false;
                } else {
                    this.$message.error(res.message)
                }
            }).catch(err => {
                this.btnLoading = false;
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
            })
        }

    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>